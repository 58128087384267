@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;500;900&family=Raleway+Dots&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
}

html,
body,
.container {
  margin: 0;
  height: 100vh;
  color: white;
}

body {
  font-family: "Inconsolata", monospace;
}

p {
  font-size: 0.8em;
  font-weight: 200;
}

a {
  text-decoration: none;
  color: white;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 1.4em;
}

h2 {
  font-size: 1.2em;
}

.header {
  background-color: #00334c;
  position: fixed;
  z-index: 1000;
  width: 100%;
  text-align: left;
  top: 0;
}

.shadow {
  -webkit-box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
}

.footer {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  background-image: url("/public/title.jpg");
  padding: 40px;
  color: white;
}
.praxis {
  width: 80%;
  margin: auto;
}
.praxis ul {
  width: 90%;
  margin-left: 100px;
  text-align: left;
  margin: auto;
  padding-left: 0px;
}

.praxis li {
  margin-left: 15%;
  font-size: 0.8em;
}

.front {
  position: "absolute";
  top: "20vh";
  left: "10vw";
  background-color: green;
}

@media (min-width: 800px) {
  h1 {
    font-size: 1.8em;
    font-weight: 700;
  }

  h2 {
    font-size: 1.4em;
    font-weight: 600;
  }

  h3 {
    font-size: 1.3em;
    font-weight: 300;
  }

  p {
    font-size: 1em;
    font-weight: 200;
  }

  .heading h1 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .heading h2 {
    font-weight: 200;
    font-size: 50px;
  }

  .praxis {
    width: 80%;
    margin: auto;
  }

  .praxis ul {
    width: 60%;
    margin-left: 100px;
    text-align: left;
    margin: auto;
  }

  .praxis li {
    margin-left: 15%;
    font-size: 1em;
  }
}
